import {
  getTheme,
  preloadedColorThemes,
  ThemePreferenceContext,
  useThemePreferenceContext,
} from '@kinetic-ui/shared';
import {
  createTheme,
  CssBaseline,
  GlobalStyles,
  StyledEngineProvider,
  ThemeProvider,
  useTheme,
} from '@mui/material';
import { SnackbarOrigin, SnackbarProvider } from 'notistack';
import React, { memo, useMemo } from 'react';

const snackbarAnchor: SnackbarOrigin = {
  vertical: 'bottom',
  horizontal: 'center',
};

const GlobalTheme = () => {
  const theme = useTheme();
  return (
    <GlobalStyles
      styles={{
        body: {
          backgroundColor:
            theme.palette.mode === 'dark' ? theme.palette.background.paper : '#f8f8f8 !important',
        },
      }}
    />
  );
};

const Theme: React.FunctionComponent = ({ children }) => {
  const themeContextValue = useThemePreferenceContext();

  const theme = useMemo(() => {
    const activeTheme =
      preloadedColorThemes[themeContextValue.theme] || preloadedColorThemes.default;
    return createTheme(getTheme(activeTheme));
  }, [themeContextValue.theme]);

  const renderTheme = () => {
    return (
      <ThemePreferenceContext.Provider value={themeContextValue}>
        <StyledEngineProvider injectFirst>
          <ThemeProvider theme={theme}>
            <GlobalTheme />
            <CssBaseline />
            <SnackbarProvider anchorOrigin={snackbarAnchor} maxSnack={2}>
              {children}
            </SnackbarProvider>
          </ThemeProvider>
        </StyledEngineProvider>
      </ThemePreferenceContext.Provider>
    );
  };

  return renderTheme();
};

export default memo(Theme) as typeof Theme;
