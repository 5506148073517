import { useCallback, useMemo, useRef } from 'react';
import { useUserTenantPreference } from 'kineticUtils/hooks';
import { PREFERENCE_KEYS } from 'types/preferenceTypes';
import { ThemePreference } from 'contexts/ThemePreferenceContext';

export const useThemePreferenceContext = () => {
  const { value: preference, setPreference } = useUserTenantPreference<ThemePreference>({
    key: PREFERENCE_KEYS.THEME,
  });

  const latest = useRef(preference);
  latest.current = preference;

  const setTheme = useCallback(
    (theme: string) => {
      setPreference({ ...latest.current, theme });
    },
    [setPreference]
  );

  const setLightTheme = useCallback(
    (lightTheme: string) => {
      setPreference({ ...latest.current, lightTheme });
    },
    [setPreference]
  );

  const setThemes = useCallback(
    ({ theme, lightTheme }: ThemePreference) => {
      setPreference({ theme, lightTheme });
    },
    [setPreference]
  );

  const contextValue = useMemo(() => {
    return {
      theme: preference?.theme ?? null,
      lightTheme: preference?.lightTheme ?? 'default',
      setTheme,
      setLightTheme,
      setThemes,
    };
  }, [preference?.theme, preference?.lightTheme, setTheme, setLightTheme, setThemes]);
  return contextValue;
};
