type AsyncFunction = (...args: any[]) => Promise<any>;

export function debounceAsync(fn: AsyncFunction, wait: number) {
  let timeoutId: NodeJS.Timeout | undefined;

  return function (...args) {
    clearTimeout(timeoutId);

    return new Promise((resolve, reject) => {
      timeoutId = setTimeout(() => {
        fn(...args)
          .then(resolve)
          .catch(reject);
      }, wait);
    });
  };
}
