import {
  PREFERENCE_KEYS,
  useUserTenantPreference,
  UserContext,
  useUserContext,
  LayerExtentUserPreferences,
  useUserKineticPreference,
} from '@kinetic-ui/shared';
import {
  useGetDefaultMapConfigQuery,
  useGetMapLayerConfigsQuery,
  useGetOrgsQuery,
  useHasValidLicenseQuery,
} from '@kinetic-ui/data-layer';
import React, { memo, Suspense, useEffect, useRef, useState } from 'react';

import { useAuth } from 'react-oidc-context';

import LoadingScreen from '../loaders/LoadingScreen';
import { getCurrentOrganization, setCurrentOrganization } from './CurrentOrganization';
import {
  AppPreloadProps,
  OrganizationChangeEvent,
  ORG_CHANGE_EVENT,
  SelectedOrgPreference,
} from './types';

const MINIMUM_LOAD_TIME_MS = 1250;
const ORG_ERROR = '[OrganizationError]';
/**
 * Load tenant and user configurations that we want to wait for before mounting the app
 */
const AppPreload: React.FunctionComponent<AppPreloadProps> = ({ children }) => {
  const userContextValue = useUserContext();
  const [loadComplete, setLoadComplete] = useState(false);
  const startRef = useRef(new Date().getTime());
  const { value: preference, loading: defaultLoading } =
    useUserKineticPreference<SelectedOrgPreference>({ key: PREFERENCE_KEYS.DEFAULT_ORGANIZATION });
  const defaultOrg = preference?.defaultOrganization;
  const orgsResult = useGetOrgsQuery();
  const currentOrganization = getCurrentOrganization();

  // Load the default map layer config into cache.
  const { loading: licenseKeyLoading, error } = useHasValidLicenseQuery();
  const { loading: mapConfigLoading } = useGetDefaultMapConfigQuery({ skip: !currentOrganization });
  const { loading: mapLayerConfigLoading } = useGetMapLayerConfigsQuery({
    skip: !currentOrganization,
  });

  const { loading: layerExtendLoading } = useUserTenantPreference<LayerExtentUserPreferences>({
    key: PREFERENCE_KEYS.EXTENT_LAYERS,
  });

  const auth = useAuth();

  useEffect(() => {
    if (error) {
      const orgError = error.graphQLErrors.find((error) => error.message.includes(ORG_ERROR));
      if (orgError) {
        window.sessionStorage.clear();
        window.localStorage.clear();
        window.location.reload();
      }
    }
  }, [error]);

  useEffect(() => {
    const allOrganizations = orgsResult.data?.organizations;

    // wait till we load the user orgs.
    if (!allOrganizations || currentOrganization || defaultLoading || !auth.isAuthenticated) {
      return;
    }
    if (allOrganizations.length === 0) {
      throw new Error('User does not belong to any organizations!');
    }

    function setOrg(organization: string) {
      setCurrentOrganization(organization);
      window.dispatchEvent(
        new CustomEvent<OrganizationChangeEvent>(ORG_CHANGE_EVENT, {
          detail: {
            organization,
          },
        })
      );
    }

    function verifyAndSet(org: string) {
      if (!org) {
        setOrg(allOrganizations[0].id);
      } else {
        // verify the org is in all orgs.
        if (!allOrganizations.find((all) => all.id === org)) {
          console.warn(
            'detected the default org is not in the users list default to the first item'
          );
          setOrg(allOrganizations[0].id);
        } else {
          setOrg(org);
        }
      }
    }
    if (!defaultOrg) {
      setOrg(allOrganizations[0].id);
    } else {
      verifyAndSet(defaultOrg);
    }
  }, [
    auth.isAuthenticated,
    auth.user.access_token,
    currentOrganization,
    defaultLoading,
    defaultOrg,
    orgsResult.data?.organizations,
    userContextValue,
  ]);

  // Add any extra loading variables here to validate we are done loading.
  const loadingAny = !![
    mapConfigLoading,
    defaultLoading,
    orgsResult.loading,
    mapLayerConfigLoading,
    layerExtendLoading,
    licenseKeyLoading,
  ].filter(Boolean).length;

  useEffect(() => {
    let loadScreen: ReturnType<typeof setTimeout>;
    const now = new Date().getTime();

    if (userContextValue && !loadingAny) {
      loadScreen = setTimeout(
        () => setLoadComplete(true),
        Math.max(0, MINIMUM_LOAD_TIME_MS - (now - startRef.current))
      );
    }
    return () => {
      if (loadScreen) {
        clearTimeout(loadScreen);
      }
    };
  }, [defaultLoading, loadingAny, mapConfigLoading, orgsResult.loading, userContextValue]);

  return (
    <UserContext.Provider value={userContextValue}>
      <Suspense fallback={<LoadingScreen />}>
        {!loadComplete && <LoadingScreen />}
        {loadComplete && children}
      </Suspense>
    </UserContext.Provider>
  );
};

export default memo(AppPreload);
