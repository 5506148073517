import axios from 'axios';

let host;
try {
  host = process.env.REACT_APP_API_HOST;
} catch (e) {
  host = `${window.location.origin}/`;
}

export const API_HOST = host;
export const restClient = axios.create({
  baseURL: API_HOST,
});
