import { UserManagerSettings } from 'oidc-client-ts';

const origin = window.location.origin;
const pathName = window.location.pathname;
const search = window.location.search;

export const oidcConfig: UserManagerSettings = {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore loaded by env.sh and env-config.js
  authority: window._env_.OIDC_AUTHORITY,
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore loaded by env.sh and env-config.js
  client_id: window._env_.OIDC_CLIENT_ID,
  redirect_uri: origin + pathName + search,
  post_logout_redirect_uri: origin + '/ui/kinetic/',
  response_type: 'code',
  scope: 'openid profile email',
  loadUserInfo: true,
  silent_redirect_uri: origin + '/ui/kinetic/silent.html',
  automaticSilentRenew: true,
  response_mode: 'fragment',
};
