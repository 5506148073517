import { PREFERENCE_KEYS } from 'types/preferenceTypes';
import { useUserTenantPreferences } from './useUserTenantPreferences';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { debounceAsync } from './debounceAsync';

const EMPTY_PREFERENCE = {};
/** a User Tenant Preference is a user preference that is scoped to the tenant */
export const useUserTenantPreference = <TValue>({ key }: { key: PREFERENCE_KEYS }) => {
  const {
    data,
    loading,
    setPreference: _set,
    deletePreference: _delete,
  } = useUserTenantPreferences<TValue>();
  const [optimisticValue, setOptimisticValue] = useState<TValue | null>(null);

  const debouncedSet = useMemo(() => debounceAsync(_set, 1000), [_set]);

  useEffect(() => {
    if (data) {
      setOptimisticValue(null);
    }
  }, [data]);

  const setPreference = useCallback(
    (value: TValue) => {
      setOptimisticValue(value);
      return debouncedSet(key, value);
    },
    [debouncedSet, key]
  );

  const deletePreference = useCallback(() => {
    return _delete(key);
  }, [_delete, key]);

  const clearPreference = useCallback(() => {
    return setPreference({} as TValue);
  }, [setPreference]);

  return {
    value:
      optimisticValue ??
      ((data?.find(({ key: k }) => k === key)?.value ?? EMPTY_PREFERENCE) as TValue),
    loading,
    setPreference,
    deletePreference,
    clearPreference,
  };
};
