import { PREFERENCE_KEYS } from '@kinetic-ui/shared';
import { OrganizationChangeEvent, ORG_CHANGE_EVENT } from './types';

export const setCurrentOrganization = (organization: string) => {
  const current = getCurrentOrganization();
  sessionStorage.setItem(PREFERENCE_KEYS.DEFAULT_ORGANIZATION, organization);
  if (current && organization !== current) {
    window.location.reload();
  }
};

export const getCurrentOrganization = () => {
  return sessionStorage.getItem(PREFERENCE_KEYS.DEFAULT_ORGANIZATION);
};

export const clearCurrentOrganization = () => {
  sessionStorage.removeItem(PREFERENCE_KEYS.DEFAULT_ORGANIZATION);
  window.dispatchEvent(
    new CustomEvent<OrganizationChangeEvent>(ORG_CHANGE_EVENT, {
      detail: {
        organization: null,
      },
    })
  );
};
