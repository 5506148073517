import { Typography } from '@mui/material';
import { useMemo } from 'react';

import { LoadingScreenProps } from './types';

const LOADING_SCREEN_TEXT = 'Kinetic Mobility'.toUpperCase();

const LoadingScreen: React.FunctionComponent<LoadingScreenProps> = (_props) => {
  const letters = useMemo(() => {
    return LOADING_SCREEN_TEXT.split('').map((letter, i) => {
      return (
        <span
          key={i}
          style={
            // ts-ignore
            { '--i': i } as any
          }
        >
          {letter === ' ' ? <>&nbsp;</> : letter}
        </span>
      );
    });
  }, []);
  const renderLoadingScreen = () => {
    return (
      <div
        style={{
          position: 'fixed',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: 'white',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Typography component='div'>
          <div className='loader'>{letters}</div>
        </Typography>
      </div>
    );
  };

  return renderLoadingScreen();
};

export default LoadingScreen;
