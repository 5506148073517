// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface AppPreloadProps {}

export interface SelectedOrgPreference {
  defaultOrganization: string;
}

export const ORG_CHANGE_EVENT = 'currentOrganizationChanged';

export interface OrganizationChangeEvent {
  organization: string;
}
