export const setupMocks = async () => {
  let enableMock = false;
  try {
    enableMock = process.env.REACT_APP_MOCK_RESPONSES === 'true';
  } catch (e) {
    enableMock = false;
  }

  if (enableMock) {
    // compiler hung up on this import if you don't have mocks built
    // using webpack magic comment to not crash at compile time
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const { worker } = await import(/* webpackIgnore: true */ '@kinetic-ui/mocks');
    worker.start({
      serviceWorker: { url: `${process.env.PUBLIC_URL}/mockServiceWorker.js` },
    });
  }
};
