import { ApolloLink, Operation } from '@apollo/client';
import { Observable } from '@apollo/client/utilities';
import { BatchHttpLink } from '@apollo/client/link/batch-http';

type Headers = Record<string, string>;

export class CustomBatchHttpLink extends ApolloLink {
  private uri: string;
  private headers: Headers;
  private batchHttpLinks: Map<string, BatchHttpLink> = new Map();

  constructor({ uri, headers }: { uri: string; headers: Headers }) {
    super();
    this.uri = uri;
    this.headers = headers;
  }

  public request(operation: Operation): Observable<any> {
    const context = operation.getContext();
    const { batchMax, batchInterval, batchKey } = context.batch;

    if (!this.batchHttpLinks.has(batchKey)) {
      this.batchHttpLinks.set(
        batchKey,
        new BatchHttpLink({
          headers: this.headers,
          uri: this.uri,
          batchMax,
          batchInterval: batchInterval,
          batchKey: (operation) => operation.getContext().batch.batchKey,
        })
      );
    }

    return this.batchHttpLinks.get(batchKey).request(operation);
  }
}
